import React from 'react';
import Carousel from 'react-elastic-carousel';

import politica from '../assets/img/demo_pol.png';
import elecciones from '../assets/img/demo_elec.png';
import agua from '../assets/img/demo_agua.png';
import lavanderia from '../assets/img/demo_lavanderia.png';
import zapateria from '../assets/img/demo_zapateria.png';

export default function Gallery() {
   return (
      <div id='demos' className='demos-container'>
         <h1 className='light-title'>
            Galería
         </h1>
         <Carousel className='demos-card' pagination={false}>

            <div>
               <h2>Política</h2>
               <p className='demo-title'>
                  Plataforma de análisis político.
               </p>
               <p className='demo-description'>
                  Plataforma de análisis político histórico con poderosos filtros, herramientas comparativas y análisis geográfico.
               </p>
               <a href={'https://pol21-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>

            <div>
               <h2>Elecciones León, Gto</h2>
               <p className='demo-title'>
                  Análisis estadístico avanzado de elecciones.
               </p>
               <p className='demo-description'>
                  Plataforma de análisis de elecciones, partidos y votantes de León de los Aldama.
               </p>
               <a href={'https://leon-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>

            <div>
               <h2>Agua potable</h2>
               <p className='demo-title'>
                  Negocio de agua potable.
               </p>
               <p className='demo-description'>
                  En este negocio se depura y distribuye agua, producto de primera necesidad, a través de un vehículo.
               </p>
               <a href={'https://aguadb-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>

            <div>
               <h2>Lavandería / Tintorería</h2>
               <p className='demo-title'>
                  Negocio de lavado de ropa y planchado.
               </p>
               <p className='demo-description'>
                  En este negocio el cuidado y el control lo son todo para lograr tener la preferencia de los clientes.
               </p>
               <a href={'https://dosm-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>

            <div>
               <h2>Gasolinera</h2>
               <p className='demo-title'>
                  Negocio de distribucion de combustible.
               </p>
               <p className='demo-description'>
                  En un negocio de alta competitividad como este, es indispensable tener información sintetizada lista para ser analizada.
               </p>
               <a href={'https://gasdb-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>
            <div>
               <h2>Zapatería</h2>
               <p className='demo-title'>
                  Negocio de venta de Zapato.
               </p>
               <p className='demo-description'>
                  Donde la temporada, moda y tendencias son lo primordial para maximizar ventas, la información de series de tiempo es invaluable para este negocio.
               </p>
              <a href={'https://fsdb-prod-g5h35rw4tq-uc.a.run.app'} 
                  rule='noopener noreferer' target='_blank' className='demos-item'>
                  <p>Ir a demo</p>
               </a>
            </div>

         </Carousel>
      </div>
   )
}
